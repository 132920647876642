import * as React from "react";
import { NavProvider } from "./nav-context";

const RootProvider = ({ element }) => {
  return (
    <NavProvider>
      {element}
    </NavProvider>
  );
};

export default RootProvider;
