exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-ibaraki-js": () => import("./../../../src/pages/about-ibaraki.js" /* webpackChunkName: "component---src-pages-about-ibaraki-js" */),
  "component---src-pages-for-teacher-js": () => import("./../../../src/pages/for-teacher.js" /* webpackChunkName: "component---src-pages-for-teacher-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interview-01-js": () => import("./../../../src/pages/interview-01.js" /* webpackChunkName: "component---src-pages-interview-01-js" */),
  "component---src-pages-interview-02-js": () => import("./../../../src/pages/interview-02.js" /* webpackChunkName: "component---src-pages-interview-02-js" */),
  "component---src-pages-interview-03-js": () => import("./../../../src/pages/interview-03.js" /* webpackChunkName: "component---src-pages-interview-03-js" */),
  "component---src-pages-interview-04-js": () => import("./../../../src/pages/interview-04.js" /* webpackChunkName: "component---src-pages-interview-04-js" */),
  "component---src-pages-interview-05-js": () => import("./../../../src/pages/interview-05.js" /* webpackChunkName: "component---src-pages-interview-05-js" */),
  "component---src-pages-interview-06-js": () => import("./../../../src/pages/interview-06.js" /* webpackChunkName: "component---src-pages-interview-06-js" */),
  "component---src-pages-manga-elementary-school-js": () => import("./../../../src/pages/manga-elementary-school.js" /* webpackChunkName: "component---src-pages-manga-elementary-school-js" */),
  "component---src-pages-manga-middle-school-js": () => import("./../../../src/pages/manga-middle-school.js" /* webpackChunkName: "component---src-pages-manga-middle-school-js" */)
}

